
import * as Components from "./Tortas"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "tortas"
}
