import React from "react"

function SvgComponent(props) {
  // console.log("das", props.themeName)
  const edgeColor = (props.themeName === 'dark') ? '#FFFFFF' : '#000000'
  return (
    <svg
      className="nav-bar-logo"
      viewBox="0 0 198.9 198.1" {...props}>
      <path
        fill={edgeColor}
        d="M27.3 87.7c0-7.1 2.3-13.5 6.2-19.1 2.6-3.6 6-6.9 9.7-9.5 3.9-2.8 8.8-4.2 13.7-4.5 2.6-.2 5.1-.8 6.9-2.3 5.7-4.9 12.6-6.3 19.6-7.7 5.4-1.1 10.9-1.1 16.4-.9h.2c10.9.5 21.4 2.7 30.6 9.1 1.3.9 2.6 1.2 4.1 1.1 13.9-.4 24.8 5.9 33.1 16.4 4.1 5.1 7.7 11.1 8.1 18.1-3.3 1.9 0 5.8-2.4 8H29.1c-1.8-2.8.6-6.2-1.8-8.7zm10.1 1h127.8c-.9-12.7-13.1-24.8-24.8-24.8 1.9 5 5.5 8.1 10.5 9.5 2.2.6 4.5 1.2 4 4.1-.5 2.9-3.3 2.7-5.4 3.3-.9.2-1.9-.3-2.9-.5-6.7-1.5-10.9-6.2-14.1-11.6-3.2-5.6-7.9-8.7-13.6-11-14.4-5.7-28.9-4.5-43.5-1.3-1 .2-3.6.5-3.6 3.2 2.8 2.7 4.7 6.5 7.1 9.8 2.7 3.7 6.6 4.8 10.4 6.4 2.7 1.2 2.4 3.8-.1 5.4-1.8 1.1-3.9 1.1-5.8.7-4.1-.9-7.8-2.7-10.7-6-2.5-2.9-4.6-6.1-6.4-9.5-1.3-2.4-2.9-3.2-5.5-3-10.7.7-18.1 6.4-21.3 16.7-.9 2.5-1.3 5.3-2.1 8.6zM141.4 181.8c-6.2 6.3-14.2 8.4-22.4 9.8-8.3 1.5-16.6 1.5-24.9 0-7.8-1.4-15.5-3.5-22.2-8.2-1.5-1.1-3-1.1-4.7-1.1-14.1.2-25.2-6.2-33.2-17.2-4.3-5.9-7.5-12.7-7.7-20.4 2.1-2.1.7-4.7 1.1-7 11.5-.1 22.9-.2 34.4-.2H170c1.2 0 2.3.1 3.5.2.1.5.4.9.9 1 .9 1 1.5 2.1 1.3 3.5-.9 9-1.7 18-7 25.8-2.5 3.6-5.2 6.9-9.3 8.7-1.6-1.7-1.8-.4-2 .9-1.8 1.1-3.5 2.4-5.8 2.1-.6.1-1 .4-1.2 1-.5.4-1.1.6-1.8.4-3.5-2.1-5.4-1.9-7.2.7zm23-35.2c.1-.6.7-1.5 0-1.7-1.1-.3-2.3-.1-3.5-.1H42.4c-1.2 0-2.3 0-3.5.1-1.3.1-1.4 1.1-1.5 2.2-2 2.1.6 3.5 1 5.2.8 3.3 1.8 6.8 4 9.4 3.9 4.6 8.6 8.2 14.5 10.1 1.7.6 3.3 2 5.2.6-4.4-6.8-4.4-6.8-11.6-9.7l-.9-.3c-2.8-1.5-3-4.6-.3-6.4 2-1.3 4.2-1.4 6.2-.3 5.1 2.6 10.4 4.6 13.2 10.7 1.6 3.4 4.1 6.9 7.8 9.2 11.3 7 34.3 10.3 50.2 4.4 1.8-.7 2.9-1.5 4.3-3.3-3.1-.3-2.4-3.1-3.8-4.8-3.9-4.6-7.3-9.6-13.5-11.2-.3-.1-.5-.4-.8-.6-2.4-2-2.2-3.9.5-5.4 1.4-.7 2.9-1.1 4.4-.8 5 1 9.6 2.8 12.6 7.3 1.8 2.8 3.9 5.4 5.4 8.4 1.4 2.9 3.3 3.7 6.3 3.5 13.1-1.1 18.7-8.2 22.2-20.2.4-2 1.7-4.2.1-6.3z"
      />
      <path
        fill="#D22B24"
        d="M45.2 118.1c.7-3.1 1.3-6.2 2-9.2.3-1.1.4-2.4.9-3.3 1.2-2.1 2.5-4.5 5.5-4.2 2.9.3 4.4 2.4 4.8 5 1.1 7.2 1.9 14.5 2.8 21.8.3 2.3.5 4.5.7 7-2.4.5-4.5.4-7.1.1-.6-6.8-1.1-13.5-1.7-20.3-2.9 0-2.8 2-2.8 3.8.2 4.6-1 8.9-2 13.3-.4 1.8-1.3 3.5-3.6 3.6-2.3 0-3.2-1.7-3.6-3.5-1.1-4.9-2-9.8-2.9-14.7-.4-1.8-.8-3.6-2.1-5.4-.7 7.6-1.3 15.2-2 22.9-2.2 1-4.4.7-7.2.2 1.2-9.7 2.4-19.3 3.6-28.8.4-2.9 2.6-4.2 5-4.9 1.7-.5 5.5 2.6 6 4.8 1 4 1.9 8 2.8 12 .4 0 .7-.1.9-.2z"
      />
      <path
        fill="#0C8F43"
        d="M166.7 19.6c.6 5.4-2.5 9.2-4.3 13.5-2.6 6.1-4.7 12.4-7.2 18.9-2.9-.8-5.4-2-8-3.5 1.1-3.2 2.2-6.3 3.4-9.5-2.4-1.8-5-2.8-7.8-3.7-1.7 3.6-1 7.2-1.2 10.9-2.5.8-4.8 1.5-7.2 1.6-3.3-9.9.5-23 8.3-29 3.7-2.9 7.9-4.1 12.6-3.3 4.1.8 8 2.2 11.4 4.1zM156.5 24c-4.3-1-7.4 0-9.9 3.6 2.5 1.1 4.7 2.1 7 3 1-2.3 1.9-4.2 2.9-6.6zM100 43.8h-.2c-4.9-5.7-8.8-12.2-13.7-18.2.3 4.6.7 9.1.8 13.8-2.8.3-5.4.5-8.5.8-.7-8.6-1.3-17-2-25.4 2.7-.4 5.5-.9 8.2-.7 1.2 2.8.4 5.8 1.4 8.5 3.2-.8 3.5-4 5.3-5.7 1.1-1 1.7-2.6.7-4.1-.9-1.3-2.4-.9-3.7-.8-4.5.4-8.9 1-13.4.9l.3-3.3c0-.3.1-.6.1-1 .3-2.5 1.4-3.7 4.2-3.8 4.1-.1 8.3-.7 12.4-1 2.1-.1 4.3 0 6.1 1.6 5 4.2 5.3 10.3.7 15.2-.8.8-1.6 1.7-2.5 2.7 2.6 5.2 7.2 8.7 10.6 14.5-2.2 2-4.5 4-6.8 6zM56.5 46.8c-4.2 1-7.1-1.7-10-4.6-4.2-4.3-6.4-9.6-7.4-15.4-.4-2.4-.1-4.6 1-6.8 2.8-5.4 13.5-10.1 19.4-8.6 2.8.7 4.8 2.5 6 4.9 4.2 7.9 4.5 16.1 1.5 24.4-1.7 4.6-5 6.3-10.5 6.1zm4-15.3c-.1-1.2-.1-2.7-.3-4.2-.2-1.1-.5-2.3-.9-3.3-1.5-4-3.1-5-6.6-3.9-4.1 1.3-5.5 3.4-4.4 7.4.6 2.4 1.8 4.6 3.2 6.7 1.6 2.5 3.1 5.9 6.5 5 3.2-1 2.2-4.7 2.5-7.7z"
      />
      <path
        fill="#D22B24"
        d="M177.4 118.4c.1 4.5-1.2 8.4-4 12-3.6 4.6-8.3 5.1-13.7 4.6-8.5-.7-11.6-6.4-12.9-13.4-1.4-7.2 1-13.5 7.1-17.9 6.5-4.7 15.1-3 19.7 3.6 3.1 4.7 3.8 6.7 3.8 11.1zm-23.2.4c.1 5 3.7 9.1 8 8.9 4.1-.2 7.6-4.2 7.6-9 0-5-3.5-9.1-7.9-9.1s-7.7 4-7.7 9.2zM111.1 127.8v7.3c-5.4.5-9.2-2.4-13-5.8-3.4 3.7-7.2 6-12.3 6.1-.7-2.4-.2-4.7-.3-7.3 5.2-1.4 9.2-4 8.7-10.2-.4-5.2-3.9-7.4-8.5-8.3-.4-2.5-.3-4.8-.1-7.4 5.2-.3 8.9 2.1 12.2 5.7 4-2.7 7.5-6.3 12.9-5.8.9 2.3.4 4.6.4 7.4-5.1 1.1-9 3.6-8.8 9.5.2 5.3 3.7 7.8 8.8 8.8z"
      />
      <path
        fill="#0C8F43"
        d="M160.9 55.1c.4-1.5 2.1-3.4 3.7-5.7 2.8 2 4.6 5.1 7.4 6.8 1.8-.7 1.4-2 1.4-3.1.2-3.5.4-7 .5-10.4.1-3 1.1-5.5 3.1-7.7 2.6-2.8 6.1-3.2 8.8-.6 2.6 2.5 5 5.3 7.7 8.1-1.2 2.3-2.3 4.5-3.5 6.7-2.8-1.2-3.8-4.6-7.5-5.6-.2 3.9-.4 7.5-.5 11.1-.1 2.2-.2 4.3-.9 6.4-1.9 5.8-7.6 7.6-12.1 3.5-2.7-2.7-5-5.9-8.1-9.5z"
      />
      <path
        fill="#D22B24"
        d="M82.8 135.3c-6.6.4-12.3-.8-15.9-6.3-3.9-6-4.8-12.6-1.1-19.1 3.7-6.4 9.5-8.4 16.6-7.9.9 2.4.7 4.8.3 7.2-7.7 1.2-7.7 1.2-10.3 5.4h9.9c1.1 2.6.7 4.9.5 7.3-3.4 1.4-7-.1-10.5 1 2 4.7 6.1 4.6 10.2 4.9.9 2.5.4 4.8.3 7.5z"
      />
      <path
        fill="#0C8F43"
        d="M123.4 41.3c-3-.5-5.4-1-8.2-1.5.5-8.9 2.6-17.5 3.3-26.1-2.6-1.5-5.5-.8-8.2-2.4.4-2.4 1.7-4.4 2.8-7.2 7.4 1.2 14.7 2.4 22.1 3.7.3 2.8.6 5.2.8 7.6-3 .9-5.4-.6-8.5-.6-2 8.6-2.5 17.4-4.1 26.5zM38.5 57.8c-2.5 2.1-4.6 3.9-7.1 6C26.2 57.4 21 51 15.8 44.5c-2.9 1.1-4 4.2-6.9 5.3-1.1-2.1-2.1-4.1-3.4-6.5 5.7-5.4 10.7-11.6 16.9-16.5 2.5.7 3 3.3 5.1 4.3-1.8 2.4-3.5 4.8-5.2 7.2 5.3 6.4 10.1 13.1 16.2 19.5z"
      />
      <path
        fill="#D22B24"
        d="M144.1 102.3v7c-9.1 1.7-11.6 3.5-11.5 8.3.2 7.1 2.3 9.1 11.4 10.4.6 2.4.4 4.8.1 7.4-6.8.5-12.5-1.2-16.2-6.9-3.8-5.8-4.4-12.3-.7-18 3.5-5.7 8.8-10 16.9-8.2z"
      />
      <path
        fill={edgeColor}
        d="M27.3 87.7c2.4 2.5 0 5.8 1.8 8.7h144.4c2.3-2.1-1-6.1 2.4-8 .8 3.3.7 6.6.2 10H27.4c-1.1-3.5-1.1-7.1-.1-10.7z"
      />
      <path
        fill="#D22B24"
        d="M121.2 135.3c-2.2.5-4.3.3-6.7.2v-33.4h6.7v33.2z"
      />
      <path
        fill={edgeColor}
        d="M141.4 181.8c1.9-2.6 3.7-2.8 7.2-.7-2.4 1.1-4.8.6-7.2.7zM27.4 137.8c-.4 2.3 1 5-1.1 7-.4-2.4-1.9-5.1 1.1-7zM157.4 177.7c.2-1.3.4-2.6 2-.9-.5.7-1.1 1.2-2 .9zM174.3 138.8c-.5-.1-.8-.4-.9-1 .7-.1 1 .2.9 1zM150.4 180.8c.2-.6.6-.9 1.2-1-.3.6-.5 1.1-1.2 1zM164.4 146.6c-10.3 0-20.6.1-30.9.1-30.4 0-60.8-.1-91.2-.1-1.6 0-3.3-.3-4.9.4.1-1 .2-2.1 1.5-2.2 1.2-.1 2.3-.1 3.5-.1h118.5c1.2 0 2.4-.2 3.5.1.7.3.1 1.2 0 1.8z"
      />
    </svg>
  )
}

export default SvgComponent
