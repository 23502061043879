import React, {useState} from 'react'
import useStripe from './useStripe'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
import './GiftCards.scss'
import axios from 'axios'
import getEndpoint from '../../utils/getEndpoint'
import GiftCardPreview from './GiftCardPreview'
import ScrollToTop from '../../react/ScrollToTop'

const SESSION_ENDPOINT = getEndpoint('giftcard-session')

const options = [25,50,75,100]
export default function (props) {
  const {status} = props.match.params
  const stripe = useStripe()
  const [selectedAmount, setSelectedAmount] = useState(50)
  const [note, setNote] = useState('')
  const [buyerName, setBuyerName] = useState('')
  const [recipientName, setRecipientName] = useState('')
  const [buyerEmail, setBuyerEmail] = useState('')
  const [recipientEmail, setRecipientEmail] = useState('')
  if (!stripe) {
    return (<div>loading...</div>)
  }
  const data = {
    selectedAmount,
    note,
    buyerName,
    recipientName,
    buyerEmail,
    recipientEmail,
    r_id: 'romancing',
    success_url: 'http://romancingthebeancafe.com/gift-cards/success',
    failure_url: 'http://romancingthebeancafe.com/gift-cards/failure',
  }
  function submitForm (e) {
    e.preventDefault()
    const dataToSubmit = Object.assign({}, data, {
      note: data.note + " romancing.com"
    })
    axios.post(SESSION_ENDPOINT, dataToSubmit).then((result) => {
      const {id: sessionId} = result.data
      stripe.redirectToCheckout({
        sessionId
      })
    })
  }

  console.log('picked: ', selectedAmount)
  return(
    <div className="gift-cards-page">
      <ScrollToTop></ScrollToTop>


      <h1>eGift Card</h1>

      {(status === 'success') && <div className="success status">
        <h2>Payment Successful!</h2>

        <p>Please wait while we process the gift card and send you an email confirmation.</p>
        <p>Questions? Call/Text 737-333-1680</p>
      </div>}

      {(status === 'failure') && <div className="failure status">
        <h2>Payment Canceled</h2>

        {/* <p>Please wait while we process the gift card and send you an email confirmation.</p> */}
        <p>Questions? Call/Text 737-333-1680</p>
      </div>}

      <div className="content">
        <div className="top-preview">
          <GiftCardPreview data={data} />
        </div>

        <form
          className="data-form"
          action="" onSubmit={submitForm}>

          <fieldset>
            <div className="gift-card-options">
              {options.map((optionAmount) => {
                const id = `gift-card-preset-${optionAmount}`
                const checked = !!(Number(selectedAmount) === Number(optionAmount))
                return(
                  <div
                    className={checked ? "picked option" : "option"}
                    >
                    <input
                      type="radio"
                      value={selectedAmount}
                      id={id}
                      checked={checked}
                      onChange={(e) => setSelectedAmount(optionAmount)}
                      name="gift-card-preset-amount"/>
                    <label htmlFor={id}>${optionAmount}</label>
                  </div>
                )
              })}
            </div>
          </fieldset>
          <div className="gift-card-custom-option">
            <label htmlFor="gift-card-custom-amount">
              Custom amount:
            </label>
            <div>
              $<input
                id="gift-card-custom-amount"
                onChange={(e) => setSelectedAmount((e.target.value))}
                value={selectedAmount}
                type="number"/>
            </div>
          </div>

          <div className="info">
            <div className="input-group">
              <label htmlFor="gift-sender-name">Sender Name:</label>
              <input
                value={buyerName} onChange={(e) => setBuyerName(e.target.value)}
                type="text" id="gift-sender-name"/>
            </div>
            <div className="input-group">
              <label htmlFor="gift-sender-email">Sender Email:</label>
              <input
                value={buyerEmail} onChange={(e) => setBuyerEmail(e.target.value)}
                type="text" id="gift-sender-email"/>
            </div>
            <div className="input-group">
              <label htmlFor="gift-recipient-name">Recipient Name:</label>
              <input
                value={recipientName} onChange={(e) => setRecipientName(e.target.value)}
                type="text" id="gift-recipient-name"/>
            </div>
            <div className="input-group">
              <label htmlFor="gift-recipient-email">Recipient Email:</label>
              <input
                value={recipientEmail} onChange={(e) => setRecipientEmail(e.target.value)}
                type="text" id="gift-recipient-email"/>
            </div>
            <div className="input-group">
              <label htmlFor="gift-note">Note for Recipient:</label>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                name="gift-note" id="" cols="30" rows="5">
              </textarea>
            </div>

          </div>

          <h2>Preview</h2>

          <GiftCardPreview data={data} />

          <div><input
              className="submit"
              value="Secure Pay"
              type="submit"
            />
            <p>Questions?<br/> Call/Text 737-333-1680</p>
          </div>
        </form>
      </div>
    </div>
  )
}
